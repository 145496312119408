import React from "react";
import styled from "styled-components";
import config from "../../data/SiteConfig";
import { CONSTANTS } from "../constants";
import { VisuallyHidden } from "./shared";

export default function Footer() {
  return (
    <Wrapper>
      <CopyrightText>
        {config.copyright} Developed by{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://jokosanyang.com"
        >
          Joko Sanyang
          <VisuallyHidden>(Opens in new tab)</VisuallyHidden>
        </a>
        .
      </CopyrightText>
      <CopyrightText>
        {" "}
        Icons made by{" "}
        <a href="https://www.flaticon.com/authors/anatoly" title="Anatoly">
          Anatoly
        </a>{" "}
        from{" "}
        <a href="https://www.flaticon.com/" title="Flaticon">
          {" "}
          www.flaticon.com
        </a>
        .
      </CopyrightText>
    </Wrapper>
  );
}

const Wrapper = styled.footer`
  padding: ${CONSTANTS.space[2]};
`;

const CopyrightText = styled.p`
  max-width: ${CONSTANTS.contentWidth};
  margin: ${CONSTANTS.space[1]} auto 0 auto;
  font-size: ${CONSTANTS.font.size.small};
  color: ${CONSTANTS.color.textInverse};
`;
