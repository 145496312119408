export const CONSTANTS = {
  contentWidth: "1000px",
  color: {
    main: "#121212",
    accent: "#121212",
    text: "",
    textSecondary: "dimgray",
    textInverse: "#f7f6f2",
    border: "#d8d5d5",
  },
  space: ["4px", "8px", "12px", "16px", "24px", "32px", "40px", "48px"],
  font: {
    size: {
      small: "14px",
      body: "18px",
      medium: "20px",
      large: "24px",
      subheading: "28px",
      title: "32px",
    },
    weight: {
      thin: 300,
      normal: 400,
      semibold: 500,
      bold: 600,
    },
  },
};
