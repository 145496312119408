import styled, { css } from "styled-components";
import { CONSTANTS } from "../constants";

const buttonreset = css`
  margin: initial;
  padding: initial;
  border: 0;
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  text-align: inherit;
  text-transform: inherit;
  background-color: transparent;
`;

export const buttonStyles = css`
  ${buttonreset}
  padding: ${CONSTANTS.space[1]} ${CONSTANTS.space[5]};
  color: ${CONSTANTS.color.textInverse};
  background-color: ${CONSTANTS.color.accent};
  border-radius: 4px;
`;

export const VisuallyHidden = styled.span`
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
  white-space: nowrap;
`;

export const Wrapper = styled.div`
  width: 100%;
  margin: ${CONSTANTS.space[5]} 0;
`;

export const SectionHeading = styled.h2`
  font-size: ${CONSTANTS.font.size.subheading};
  font-weight: ${CONSTANTS.font.weight.semibold};
`;

export const Button = styled.button`
  ${buttonStyles}
`;

export const BlockLink = styled.a`
  ${buttonStyles}
  display: inline-block;
`;
