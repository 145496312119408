import React from "react";
import Helmet from "react-helmet";
import urljoin from "url-join";
import config from "../../data/SiteConfig";

export default function SEO() {
  const { siteTitle, siteDescription, favicon, siteLogo, pathPrefix } = config;
  const siteUrl = urljoin(config.siteUrl, pathPrefix);

  let image = siteLogo;

  if (image && image.indexOf("http") === -1)
    image = urljoin(config.siteUrl, pathPrefix, image);

  const schemaOrgJSONLD = [
    {
      "@context": "http://schema.org",
      "@type": "WebSite",
      url: siteUrl,
      name: siteTitle,
      description: siteDescription,
    },
  ];

  return (
    <Helmet>
      {/* General tags */}
      <html lang="en" />
      <title>{siteTitle}</title>
      <meta name="description" content={siteDescription} />
      <meta name="image" content={image} />
      <link rel="icon" href={favicon} type="image/png" />

      {/* Schema.org tags */}
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>

      {/* OpenGraph tags */}
      <meta property="og:url" content={siteUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={siteTitle} />
      <meta property="og:description" content={siteDescription} />
      <meta property="og:image" content={image} />
      <meta property="og:locale" content="en_GB" />

      {/* Recaptcha */}
      <script src="https://www.google.com/recaptcha/api.js?render=6LfI2yAaAAAAAIoMn70WTViKOz0WHqd-_wbQGRgt"></script>
    </Helmet>
  );
}
