import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { CONSTANTS } from "../constants";
import { VisuallyHidden } from "./shared";

export default function Navigation() {
  return (
    <Wrapper>
      <FlexContainer>
        <LogoContainer>
          <Logo>
            <Link to="/">
              <h1>
                <VisuallyHidden>Sheriff's Handywork</VisuallyHidden>
                <span aria-hidden="true">sh</span>
              </h1>
            </Link>
          </Logo>
        </LogoContainer>
        <Nav>
          <NavItem>
            <Link
              to="/"
              activeStyle={{ fontWeight: CONSTANTS.font.weight.semibold }}
            >
              Home
            </Link>
          </NavItem>
          <NavItem>
            <Link
              to="/gallery"
              activeStyle={{ fontWeight: CONSTANTS.font.weight.semibold }}
            >
              Gallery{" "}
            </Link>
          </NavItem>
          <NavItem>
            <Link
              to="/contact"
              activeStyle={{ fontWeight: CONSTANTS.font.weight.semibold }}
            >
              Contact
            </Link>
          </NavItem>
        </Nav>
      </FlexContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: ${CONSTANTS.space[0]} ${CONSTANTS.space[3]};

  @media (min-width: 1000px) {
    padding: ${CONSTANTS.space[1]};
  }
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: ${CONSTANTS.contentWidth};
  margin: 0 auto;
`;

const LogoContainer = styled.div`
  margin-right: 30px;

  @media (min-width: 720px) {
    width: 50%;
  }
`;

const Logo = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: white;

  > a {
    text-decoration: none;
  }

  > a > h1 {
    margin: 0;
    font-size: ${CONSTANTS.font.size.title};
    font-weight: ${CONSTANTS.font.weight.thin};
  }
`;

const Nav = styled.ul`
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin: 0;
  padding: 0;
`;
const NavItem = styled.li`
  list-style: none;
  font-size: ${CONSTANTS.font.size.medium};
  font-weight: ${CONSTANTS.font.weight.thin};
  color: ${CONSTANTS.color.textInverse};

  > a {
    text-decoration: none;
  }
`;
