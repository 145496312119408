import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import SEO from "./SEO";
import Navigation from "./Navigation";
import Footer from "./Footer";
import { CONSTANTS } from "../constants";

const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
    font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    font-size: ${CONSTANTS.font.size.body};
    line-height: 1.4;
    background-color: ${CONSTANTS.color.main};
  }

  body {
    margin: 0;
  }

  a {
    color: inherit;
  }
`;

export default function Layout({ children }) {
  return (
    <div>
      <SEO />
      <GlobalStyle />
      <PageContainer>
        <Navigation />
        <Main>{children}</Main>
        <Footer />
      </PageContainer>
    </div>
  );
}

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  padding: ${CONSTANTS.space[4]};
  background-color: white;

  > * {
    max-width: ${CONSTANTS.contentWidth};
  }
`;
