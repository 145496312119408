const config = {
  siteTitle: "Sheriff's Handywork", // Site title.
  siteTitleShort: "Sheriff's Handywork", // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteLogo: "/sh_logo.png", // Logo used for SEO and manifest.
  siteUrl: "https://sheriffshandywork.com", // Domain of your website without pathPrefix.
  favicon: "./sh_favicon.png",
  pathPrefix: "/", // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
  siteDescription:
    "Honest, reliable handyman with over 20 years experience providing bathroom and kitchen installation, painting, decorating, flooring, gardening, home repairs, appliance installation, odd jobs, and maintenance services to London and areas within the M25. Get in touch for a no-obligation quote today!", // Website description used for RSS feeds/meta description tag.
  dateFormat: "DD/MM/YYYY", // Date format for display.
  copyright: `Copyright © ${new Date().getFullYear()} Sheriff's Handywork.`, // Copyright string for the footer of the website and RSS feed.
  themeColor: "#121212", // Used for setting manifest and progress theme colors.
  backgroundColor: "#e0e0e0", // Used for setting manifest background color.
};

// Make sure pathPrefix is empty if not needed
if (config.pathPrefix === "/") {
  config.pathPrefix = "";
} else {
  // Make sure pathPrefix only contains the first forward slash
  config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, "")}`;
}

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === "/")
  config.siteUrl = config.siteUrl.slice(0, -1);

module.exports = config;
